import * as React from "react";
import ReactDOM from "react-dom";
import { Illustration } from "react-zdog";
import { a, useSpring as useZdogSpring } from "react-spring/zdog";
import { animated, useSpring } from "react-spring";
import Div100vh from "react-div-100vh";

const triangle = (scale: number) =>
  [
    { x: 1, y: 1, z: 1 },
    { x: 1, y: -1, z: -1 },
    { x: -1, y: 1, z: -1 },
    { x: 1, y: 1, z: 1 },
    { x: -1, y: -1, z: 1 },
    { x: 1, y: -1, z: -1 },
    { move: { x: -1, y: -1, z: 1 } },
    { x: -1, y: 1, z: -1 },
  ].map(({ x, y, z, move }) => {
    if (move) {
      return {
        move: { x: move.x * scale, y: move.y * scale, z: move.z * scale },
      };
    }
    return { x: x * scale, y: y * scale, z: z * scale };
  });

function getVerticalScrollRatio(elm) {
  const p = elm.parentNode;
  const pos =
    (elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight);

  return pos;
}

function Scene({ x }) {
  const ref = React.useRef(null);

  return (
    <a.Shape
      ref={ref}
      path={triangle(80)}
      rotate={x.interpolate((x) => ({ y: (Math.PI / 4) * x }))}
      stroke={6}
      closed={false}
      color="currentColor"
    />
  );
}

function App() {
  const [buttonStyle, set] = useSpring(() => ({
    opacity: 0,
  }));

  const [{ x }, setZdog] = useZdogSpring(() => ({ x: 0 }));

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      const pos = getVerticalScrollRatio(document.body);
      if (pos > 0.9) {
        set({
          opacity: 1,
        });
        setZdog({
          x: 1,
        });
      } else {
        set({
          opacity: 0,
        });
        setZdog({
          x: pos,
        });
      }
    });
  }, []);

  return (
    <>
      <Div100vh className="fixed w-full z-20 text-gray-700">
        <Illustration zoom={1}>
          <Scene x={x} />
        </Illustration>
      </Div100vh>
      <Div100vh className="fixed w-full z-20 text-gray-700">
        <div className="flex flex-col justify-center items-center pt-2 h-full">
          <div className="space-x-4 flex">
            <animated.a
              className="focus:outline-black"
              href="https://www.linkedin.com/in/geraldurschitz/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                ...buttonStyle,
                pointerEvents: buttonStyle.opacity.interpolate((o) =>
                  o === 1 ? "all" : "none"
                ),
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="text-gray-700 w-10"
              >
                <path
                  fill="currentColor"
                  d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                />
              </svg>
            </animated.a>
            <animated.a
              className="focus:outline-black"
              href="https://github.com/gurschitz"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                ...buttonStyle,
                pointerEvents: buttonStyle.opacity.interpolate((o) =>
                  o === 1 ? "all" : "none"
                ),
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="text-gray-700 w-10"
              >
                <path
                  fill="currentColor"
                  d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-4.466 19.59c-.405.078-.534-.171-.534-.384v-2.195c0-.747-.262-1.233-.55-1.481 1.782-.198 3.654-.875 3.654-3.947 0-.874-.312-1.588-.823-2.147.082-.202.356-1.016-.079-2.117 0 0-.671-.215-2.198.82-.64-.18-1.324-.267-2.004-.271-.68.003-1.364.091-2.003.269-1.528-1.035-2.2-.82-2.2-.82-.434 1.102-.16 1.915-.077 2.118-.512.56-.824 1.273-.824 2.147 0 3.064 1.867 3.751 3.645 3.954-.229.2-.436.552-.508 1.07-.457.204-1.614.557-2.328-.666 0 0-.423-.768-1.227-.825 0 0-.78-.01-.055.487 0 0 .525.246.889 1.17 0 0 .463 1.428 2.688.944v1.489c0 .211-.129.459-.528.385-3.18-1.057-5.472-4.056-5.472-7.59 0-4.419 3.582-8 8-8s8 3.581 8 8c0 3.533-2.289 6.531-5.466 7.59z"
                />
              </svg>
            </animated.a>

            <animated.a
              className="focus:outline-black"
              href="https://twitter.com/geraldurschitz"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                ...buttonStyle,
                pointerEvents: buttonStyle.opacity.interpolate((o) =>
                  o === 1 ? "all" : "none"
                ),
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="text-gray-700 w-10"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-.139 9.237c.209 4.617-3.234 9.765-9.33 9.765-1.854 0-3.579-.543-5.032-1.475 1.742.205 3.48-.278 4.86-1.359-1.437-.027-2.649-.976-3.066-2.28.515.098 1.021.069 1.482-.056-1.579-.317-2.668-1.739-2.633-3.26.442.246.949.394 1.486.411-1.461-.977-1.875-2.907-1.016-4.383 1.619 1.986 4.038 3.293 6.766 3.43-.479-2.053 1.08-4.03 3.199-4.03.943 0 1.797.398 2.395 1.037.748-.147 1.451-.42 2.086-.796-.246.767-.766 1.41-1.443 1.816.664-.08 1.297-.256 1.885-.517-.439.656-.996 1.234-1.639 1.697z"
                />
              </svg>
            </animated.a>
          </div>
          <div style={{ height: 230, width: 200 }}></div>
          <animated.a
            className="block rounded px-4 py-2 text-gray-700 border-gray-700 border-4 font-bold hover:bg-gray-700 hover:text-gray-300 active:bg-gray-700 active:text-white focus:outline-black"
            href="mailto:gerald.urschitz@gmail.com"
            style={{
              ...buttonStyle,
              pointerEvents: buttonStyle.opacity.interpolate((o) =>
                o === 1 ? "all" : "none"
              ),
            }}
          >
            Get in touch
          </animated.a>
        </div>
      </Div100vh>
      <div className="z-10 h-full w-full">
        <Div100vh className="bg-green-300 p-4 lg:p-12 flex flex-col justify-between text-gray-700">
          <h1 className="text-5xl lg:text-9xl">Gerald Urschitz</h1>

          <small className="text-5xl lg:text-9xl text-right">
            Web Developer
          </small>
        </Div100vh>
        <Div100vh className="relative bg-blue-300 p-12 text-gray-700 flex flex-col justify-between">
          <h2 className="text-5xl lg:text-9xl">TypeScript</h2>
          <h2 className="text-5xl lg:text-9xl">HTML & CSS</h2>
          <h2 className="text-5xl lg:text-9xl">React</h2>
          <div className="absolute inset-0 z-20 flex">
            <div className="lg:flex-1"></div>
          </div>
        </Div100vh>
        <div
          className="relative bg-gray-300"
          style={{ height: "calc(100vh + 100px)" }}
        ></div>
      </div>
    </>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
